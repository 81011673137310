<template>
  <BaseDetail
    :is-loading="loading"
    v-if="detail"
  >
    <template #sidebar>
      <BaseSideBar dark>
        <v-card flat>
          <v-card-text>
            <SideBar
              :name="detail.name"
            />
          </v-card-text>
        </v-card>
      </BaseSideBar>
    </template>
    <template
      #header
      v-model="detail"
    >
      <nobr></nobr>
    </template>
    <template #content>
      <div>
        <router-view
          :key="$route.path"
          v-if="detail"
          v-model="detail"
          ref="content"
          @save="handleSave"
          @delete="handleDelete"
          @close="handleClose"
        ></router-view>
      </div>
    </template>
  </BaseDetail>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import DetailView from './detail/views/organizationDetail/DetailView'
import detail from '@/components/mixins/detail'
import { required, minLength, numeric } from 'vuelidate/lib/validators'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseSideBar from '@/components/base/BaseSideBar'
import SideBar from '@/components/views/account/organization/detail/SideBar'
import backToTables from '@/components/mixins/backToTables'

export default {
  mixins: [detail, backToTables],
  name: 'Detail',
  components: { BaseDetail, DetailView, BaseHeader, BaseSideBar, SideBar },
  validations: {
    detail: {
      name: {
        required
      },
      inn: {
        required,
        numeric,
        minLength: minLength(10)
      },
      kpp: {
        required,
        numeric,
        minLength: minLength(9)
      },
      region: {
        required
      },
      city: {
        required
      },
      address: {
        required
      },
      checking_account: {
        required,
        numeric,
        minLength: minLength(19)
      },
      correspondent_account: {
        required,
        minLength: minLength(20),
        numeric
      },
      bank: {
        required
      },
      role: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
