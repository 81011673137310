<template>
  <div>
    <v-card-title class="pb-1 pl-8 pt-0">{{ name ? name : 'Новая компания' }}</v-card-title>
    <v-divider></v-divider>
    <v-list
      dense
      dark
    >
      <v-list-item-group
        mandatory
        v-model="currentActiveItem"
      >
        <BaseListItem
          v-for="(item,i) in getMenuItems"
          :key="i+ '-'"
          :item="item"
        />
        <v-divider></v-divider>
        <v-card-subtitle class="pb-1 pl-8 pt-0">Конфигурация слоев для организации</v-card-subtitle>
        <SideBarObjects
          v-if="constructorObjects.length"
          v-for="item in constructorObjects"
          :item="item"
          :key="item.id"
          @clickObject="clickObject"
        />
      </v-list-item-group>
    </v-list>

    <v-card-subtitle v-if="$route.params.id === 'create'">&laquo;Пользователи&raquo; и &laquo;подписки&raquo; будут
      доступны после сохранения
    </v-card-subtitle>
  </div>
</template>

<script>
import { collectMenuItems, currentActiveWithOffset } from '@/components/utils/common'
import BaseListItem from '@/components/base/BaseNav/BaseListItem'
import menuItems from '@/config/menuConfigs/organizationMenuConfig'
import SideBarObjects from '@/components/views/account/organization/detail/SideBarObjects'

export default {
  name: 'SideBar',
  components: { SideBarObjects, BaseListItem },
  props: {
    name: String
  },
  data () {
    return {
      currentActiveItem: null,
      constructorObjects: null
    }
  },
  computed: {
    getMenuItems () {
      let menuList = menuItems
      if (this.$route.params.id === 'create') {
        menuList = menuList.slice(0, 1)
      }
      return collectMenuItems(menuList)
    }
  },
  methods: {
    createCurrentActiveItem () {
      const objectId = this.$route?.params?.objectId
      const indexActiveByPath = this.getMenuItems.findIndex((item) => item.title === this.$route.meta.title)
      const indexActiveByObjectId = this.constructorObjects.findIndex((item) => item.id === Number(objectId))
      this.currentActiveItem = currentActiveWithOffset(indexActiveByPath, indexActiveByObjectId, this.getMenuItems.length)
    },
    async getObjectsConstructor () {
      const objects = await this.$store.dispatch('server/get', { url: 'constructor' })
      this.constructorObjects = objects?.models || []
    },
    clickObject (id) {
      if (this.$route?.params?.objectId !== id) {
        this.$router.push({ name: 'organizationConstructorConfig', params: { objectId: id } })
      }
    }
  },
  async mounted () {
    await this.getObjectsConstructor()
    this.$nextTick(() => {
      if (this.getMenuItems) {
        this.createCurrentActiveItem()
      }
    })
  }
}
</script>

<style scoped>

</style>
