<template>
  <v-list-item
    class="pt-2"
    @click="$emit('clickObject', item.id)"
  >
    <div class="d-flex flex-row align-center mb-2 rounded">
      <div class="wrapper-icon white rounded d-flex justify-center align-center">
        <v-icon
          dense
          :color="item.style.color"
          v-text="item.icon"
        ></v-icon>
      </div>
      <div
        class="pt-2 pb-2 pl-3 white--text"
        v-text="item.entityName"
      ></div>
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'SideBarObjects',
  props: {
    item: Object
  },
  data () {
    return {
    }
  },
  computed: {
    currentOpen () {
      return this.$route?.params?.objectId
    }
  }
}
</script>

<style scoped>
  .wrapper-icon {
    height: 40px;
    width: 40px
  }
  .active-color {
    background-color: #2d4f75;
  }
</style>